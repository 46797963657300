import Vue from 'vue'
import Router from 'vue-router'

import OrderDummyPage from './pages/OrderDummyPage'
import OrderCreatePage from './pages/OrderCreatePage'
import OrderReviewPage from './pages/OrderReviewPage'
import OrderViewPage from './pages/OrderViewPage'
import NotFoundPage from './pages/NotFoundPage'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'OrderDummyPage',
      component: OrderDummyPage
    },
    {
      path: '/orders/:id(.{20})',
      name: 'OrderViewPage',
      component: OrderViewPage
    },
    {
      path: '/orders/:id(.{20})/review',
      name: 'OrderReviewPage',
      component: OrderReviewPage
    },
    {
      path: '*',
      name: 'NotFound',
      component: NotFoundPage
    }
  ]
})

export default router
