import PhoneNumber from 'awesome-phonenumber'

export default {
  install: (Vue, options) => {
    Vue.filter('phone', function (phone, format = 'international') {
      if (!phone) return phone
      const pn = new PhoneNumber(phone)
      if (!pn.isValid( )) return phone
      return pn.getNumber(format)
    })
  }
}
