










































































import firebase from '@firebase/app'
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'

@Component()
export default class OrderViewPage extends Vue {

  loading = false
  order = null

  // Виды конструкций
  constructionTypesList = {
    'windows': 'окна',
    'doors': 'двери',
    'portals': 'порталы',
    'balcony': 'балконы',
    'nonstandard': 'нестандартные изделия'
  }

  get orderId() {
    return this.$route.params.id
  }

  @Watch('loading')
  addRemoveBodyClass(val) {
    if (val === true) document.body.classList.add('loading')
    else document.body.classList.remove('loading')
  }

  print() {
    window.print()
  }

  getOrder() {
    this.loading = true
    firebase.firestore().collection('orders').doc(this.orderId).get()
      .then((documentSnapshot) => {
        if (!documentSnapshot.exists) {
          this.$router.replace({ name: 'NotFound' })
          return
        }
        this.order = documentSnapshot.data()
      })
      .finally(() => {
        this.loading = false
      })
  }

  created() {
    document.querySelector('body').classList.remove('modal-open')
    this.getOrder()
  }

}
