












import { Vue, Component, Model, Watch, Prop } from 'vue-property-decorator'

@Component()
export default class KbModal extends Vue {

  @Prop(String) readonly title!: String
  @Model('change', { type: Boolean }) readonly dialog!: boolean
  
  close() {
    this.$emit('change', false)
  }
  
}
