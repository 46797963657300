










import { Vue, Component, Watch, Prop } from 'vue-property-decorator'

@Component()
export default class NotFoundPage extends Vue {
  
    created() {
      document.body.classList.remove('loading')
    }
  
}
