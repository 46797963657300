import moment from 'moment'
import axios from 'axios'

import Vue from 'vue'

import VueMoment from 'vue-moment'
import moment from 'moment'
import 'moment/locale/ru'
Vue.use(VueMoment, { moment })

import StarRating from 'vue-star-rating'
Vue.component('StarRating', StarRating)

import VueSuggestions from 'vue-suggestions';
Vue.component({ VueSuggestions })

import vueFilterPrettyBytes from 'vue-filter-pretty-bytes'
Vue.use(vueFilterPrettyBytes)

import phoneFilter from './filters/phone'
Vue.use(phoneFilter)

import firebase from '@firebase/app'
import '@firebase/firestore'
import '@firebase/auth'
import '@firebase/storage'
import '@firebase/functions'

firebase.initializeApp({
  apiKey: "AIzaSyBwWi5A1in3sBpp3tq9RnoiBS5VIxus0vQ",
  authDomain: "okna-app.firebaseapp.com",
  databaseURL: "https://okna-app.firebaseio.com",
  projectId: "okna-app",
  storageBucket: "okna-app.appspot.com",
  messagingSenderId: "634162118584"
})

import KbModal from './components/kb-modal'
Vue.component('KbModal', KbModal)

import App from './App'
import router from './router'

const app = new Vue({
  el: '#app',
  router,
  render: h => h(App)
})
