




import { Vue, Component, Watch, Prop } from 'vue-property-decorator'

@Component
export default class OrderDummyPage extends Vue {
  created() {
    document.body.classList.remove('loading')
  }
}
