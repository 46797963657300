
























































import firebase from '@firebase/app'
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import { NoCache } from '../decorators'

@Component()
export default class OrderReviewPage extends Vue {

  loading = false
  formLoading = false
  order = null
  review = null
  defaultReview = { rating: 5, comment: '' }

  dialogThanks = false

  @Watch('loading')
  addRemoveBodyClass(val) {
    if (val === true) document.body.classList.add('loading')
    else document.body.classList.remove('loading')
  }

  get orderId() {
    return this.$route.params.id
  }

  @NoCache
  get reviewed() {
    return this.review.hasOwnProperty('id')
  }

  getReview() {
    return firebase.firestore()
      .collection('reviews')
      .where('order', '==', this.orderId)
      .get()
      .then((querySnapshot) => {
        if (!querySnapshot.docs.length) {
          return this.defaultReview
        } else {
          const doc = querySnapshot.docs[0]
          return Object.assign({ id: doc.id }, doc.data())
        }
      })
  }

  getOrder() {
    return firebase.firestore().collection('orders').doc(this.orderId).get()
      .then((documentSnapshot) => {
        if (!documentSnapshot.exists) return null
        return documentSnapshot.data()
      })
  }

  getData() {
    this.loading = true
    Promise
      .all([this.getOrder(), this.getReview()])
      .then(([order, review]) => {
        this.order = Object.assign({}, order)
        this.review = Object.assign({}, review)
      })
      .finally(() => {
        this.loading = false
      })
  }

  submit() {
    this.formLoading = true

    const review = Object.assign({
      order: this.orderId,
      contractor: this.order.contractor.id,
      customer: this.order.customer,
      enabled: true,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp()
    }, this.review)

    firebase.firestore().collection('reviews').add(review)
      .then(docRef => {
        this.review.id = docRef.id
        this.dialogThanks = true
      })
      .catch((err) => console.log)
      .finally(() => {
        this.formLoading = false
      })
  }

  created() {
    document.querySelector('body').classList.remove('modal-open')
    this.getData()
  }

}
